html,
body {
    height: 100%;
    font-family: 'regular', sans-serif !important;
}

.sidebar-props {
    background-color: #BBBDBD;
    box-sizing: border-box;
    /* padding-top: 15px; */
    /* padding-left: 30px; */
    /* width: 100%; */
    height: 100%;
    /* padding: 10px; */
}

@media only screen and (max-width: 992px) {
    .sidebar-props {
        height: auto;
        /* padding: 10px; */
    }
}

.compdisplay {
    display: block;
}

@media(max-width:1090px) {
    .compdisplay {
        display: none;
    }
    .mobdisplay {
        display: block;
    }
}

.mobdisplay {
    display: none;
}

.dashboard-props {
    padding: 0;
}

.main {
    position: absolute;
    height: 100%;
    width: 100%;
}

.heightrow {
    height: 100vh;
}

.watchVideo {
    background: #274c7f !important;
    color: #fff !important;
    height: fit-content !important;
    display: block !important;
    margin: auto 0 !important;
}

.chips {
    margin: 2px;
    text-transform: capitalize;
    background: #274c7f !important;
    color: #fff !important;
}