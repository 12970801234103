@font-face {
  font-family: 'black';
  src: url('./fonts/proximanova-black-webfont.eot');
  src: url('./fonts/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/proximanova-black-webfont.woff2') format('woff2'),
    url('./fonts/proximanova-black-webfont.woff') format('woff'),
    url('./fonts/proximanova-black-webfont.ttf') format('truetype'),
    url('./fonts/proximanova-black-webfont.svg#proxima_novablack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bold';
  src: url('./fonts/proximanova-extrabold-webfont.eot');
  src: url('./fonts/proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/proximanova-extrabold-webfont.woff2') format('woff2'),
    url('./fonts/proximanova-extrabold-webfont.woff') format('woff'),
    url('./fonts/proximanova-extrabold-webfont.ttf') format('truetype'),
    url('./fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'regular';
  src: url('./fonts/proximanova-regular-webfont.eot');
  src: url('./fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('./fonts/proximanova-regular-webfont.woff') format('woff'),
    url('./fonts/proximanova-regular-webfont.ttf') format('truetype'),
    url('./fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}




* {
  box-sizing: border-box;
}

/* body {
  background: #222D32;
  font-family: 'Roboto', sans-serif;
} */
.bg {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  background-attachment: scroll;
  background-image: url("../src/assets/images/backimg.png");

}

.bg img {
  position: absolute;
}

.bg video {
  position: absolute;
}

.bg-span {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
}

.wrapper {
  /* background: #969393; */

  /* background-image: url("../src/assets/images/banner.jpg"); */
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  background-attachment: scroll;
  /* background-image: linear-gradient(72deg, #ee5979, #144E82); */

}

.login-box {
  /* height: auto;
  background: white;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding-bottom: 20px; */

  /* margin-top: 125px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  /* background-image: url("../src/assets/images/002.png"); */
  border-radius: 30px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  position: fixed;
  width: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

/* @media(max-width:600px) {
  .login-box {
    width: 300px;
  }
} */

.login-key {
  height: 50px;
  font-size: 80px;
  line-height: 100px;
  background: -webkit-linear-gradient(#27EF9F, #0DB8DE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media(max-width:600px) {
  .login-key {
    height: 5px;
  }
}

.login-title {
  margin-top: 20px;
  text-align: center;
  font-size: 25px;
  letter-spacing: 2px;
  /* margin-top: 15px; */
  font-weight: bold;
  color: #144E82;
  margin-bottom: 15px;
  font-family: 'black', sans-serif;
}

.login-form {
  margin-top: 25px;
  text-align: left;
}

.inputloginpage {
  background: #f8f8f8;
  border: 0px;
  height: 43px;
  line-height: 43px;
  padding: 0px 15px;
  color: #777777;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 45%;
  margin: 20px 2%;
  border: 1px transparent solid;
  width: 100%;
  font-family: 'regular', sans-serif !important;
}



.form-group {
  margin-bottom: 40px;
  outline: 0px;
}

@media(max-width:600px) {
  .form-group {
    margin-bottom: 0px;
  }
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #144E82;
  outline: 0;
  background-color: white;
  color: #144E82;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0;
}

label {
  margin-bottom: 0px;
}

.form-control-label {
  font-size: 10px;
  color: #6C6C6C;
  font-weight: bold;
  letter-spacing: 1px;
}

.submitbtn {
  line-height: 32px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  outline: none !important;
  box-shadow: 0 0 10px #144E82;
  background-color: #144E82;
  color: white;
  border: 0;
  border-radius: 5px;
  width: 120px;
  height: 40px;
  float: right;
}

.submitbtn:hover {
  background: transparent;
  border: 1px solid #144E82;
  color: #144E82;
}

.submitbtn:active {
  transform: scale(0.9);
}

/*buttons*/
.commonbutton {
  position: relative;
  font-size: 14px;
  font-family: 'bold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  background: #144E82;
  padding: 15px 25px;
  display: inline-block;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  /* line-height: 28px; */
  height: 50px;
  width: 120px;
  border: 0;
  outline: none !important;
}

.commonbutton.size-2 {
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.commonbutton.size-3 {
  line-height: 20px;
  padding: 10px 12px;
  font-size: 10px;
}

.commonbutton.type-1 {
  background: #fff;
  color: #144E82;
  text-shadow: none;
  box-shadow: none;
}

.commonbutton:after {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 5px;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  content: "";
}

.commonbutton.type-1:after {
  border: 2px #144E82 solid;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
}

.commonbutton span {
  position: relative;
  float: left;
  z-index: 1;
}

.commonbutton:hover {
  color: #fff;
}

.commonbutton.type-1:hover {
  background: #144E82;
}

.commonbutton:hover:after {
  width: 100%;
  background: rgba(0, 0, 0, 0.02);
}

.commonbutton.black {
  background: #222222 !important;
  color: #144E82;
}

.commonbutton.black:hover {
  color: #fff;
}

.commonbutton.black:after {
  background: #000;
}

.registerbtn {
  line-height: 32px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  outline: none !important;
  box-shadow: 0 0 10px #144E82;
  background-color: #144E82;
  color: white;
  border: 0;
  border-radius: 5px;
  width: 130px;
  height: 40px;




}

.registerbtn:hover {
  background: transparent;
  border: 1px solid #144E82;
  color: #144E82;
}

.registerbtn:active {
  transform: scale(0.9);
}

.marginrightbtn {
  margin-right: 10px;
}

.login-btm {
  float: left;
}

.login-button {
  padding-right: 0px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 20px;
}

.login-text {
  text-align: left;
  padding-left: 0px;
  color: #A2A4A4;
}

.loginbttm {
  padding: 0px;
}

.container2 {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container2 {
    max-width: 700px;
  }
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: auto;
  z-index: 100;
  margin-bottom: 10px;
}

.footer-text {
  font-size: 1.2vw;
  text-align: center;
  color: white;
  margin-bottom: 0px;
  text-decoration: none;

}


@media(max-width:600px) {
  .footer-text {
    text-align: center;
    color: white;
    font-size: 10px;
    margin-bottom: 0px;
  }


}

.melzotext {
  color: white !important;
  margin-left: 5px;
  text-decoration: underline !important;

}

.melzotext:hover {
  color: white !important;
  margin-left: 5px;
  text-decoration: underline !important;
}

.logoposition {
  position: fixed;
  top: 20px;
  left: 20px;



}



.flexlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.widthsalemaxlogo {
  width: 130px;
}

@media(max-width:600px) {

  .widthsalemaxlogo {
    width: 70px;
  }
}

.widthangleslogo {
  width: 300px;
}



.underline {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.MuiTypography-h6 {
  color: #144E82;
  font-weight: bold !important;
  font-size: 30px !important;
}

.MuiDialog-paperWidthSm {
  width: 300px;
}

.logopositionleft {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

.widthangleslogo {
  width: 140px;
}

@media(max-width:600px) {
  .widthangleslogo {
    width: 100px;
  }
}

.logopositionright {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.widthboihringerlogo {
  width: 140px;
}

@media(max-width:600px) {
  .widthboihringerlogo {
    width: 100px;
  }
}
.registerbutton {
  color: #fff;
  font-family: "JosefinSans-600", sans-serif;
  display: inline-block;
  text-align: center;
  line-height: 47px;
  background-color: #144E82;
  border: 1.5px solid #144E82;
  height: 45px;
  width: 130px;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #fff, #fff 50%, #144E82 50%);
  background-size: 210% 100%;
  background-position: 99%;
  cursor: pointer;
}

.registerbutton:hover {
  transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
  color: #144E82;
}