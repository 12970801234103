@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

:root {
    /*Customize colors*/
    --overlay-color: #144E82;
    --button-color: #144E82;
    --button-border-color: #144E82;
    --overlay-gradiant1: rgb(21, 86, 133);
    --overlay-gradiant2: #144E82;
}





* {
    box-sizing: border-box;
}

body {
    /* font-family: 'Montserrat', sans-serif !important; */
    background-color: #eee;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    /* height: 100vh; */
    /* margin: 10px 0 50px; */
    /* padding: 20px 10%; */
    font-family: 'regular', sans-serif !important;
    color: #144E82 !important;
    overflow-x: hidden;
}

.text {
    font-weight: bold !important;
    font-size: 30px !important;
    margin: 0;

}

p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}

a {
    color: #333 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    margin: 50px 0;
}

.bodyheight {
    height: 100vh;
}

.container {
    background: white;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px !important;
    max-width: 100%;
    min-height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* top: 50%; */
    /* margin-top: -250px; */
    /* height: 100vh; */
}

@media only screen and (max-width: 600px) {
    .container {
        width: 375px !important;
    }
}

.form-container form {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.social-container {
    margin: 21px 0;
}

.social-container a {
    border: 1px solid #ddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

.form-container input {
    background: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;

    border-radius: 5px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 13px;
}

@media only screen and (max-width: 600px) {

    .form-container input {
        width: 120px;
    }
}

.form-container input:focus {
    outline: none;
}

.BUTTON {
    border-radius: 20px !important;
    border: 1px solid var(--button-border-color);
    background: transparent;
    /* background: linear-gradient(to right, var(--overlay-gradiant1), var(--overlay-gradiant2)) no-repeat 0 0 / cover; */
    color: #144E82;
    font-size: 12px !important;
    font-weight: bold;
    padding: 12px 15px;
    letter-spacing: 1px;
    text-transform: uppercase !important;
    transition: transform 80ms ease-in;
    outline: none !important;
    width: 232px !important;
    margin-bottom: 20px;

}

.BUTTON:hover {
    background-color: #144E82;
    color: white;
}

@media only screen and (max-width: 600px) {
    .BUTTON {
        width: 150px !important;
    }
}

.BUTTON:active {
    transform: scale(0.95);
}

.BUTTON:focus {
    outline: none;
}

.BUTTON.login_button {
    background: transparent !important;
    border-color: white;
    margin-top: 10px;
    color: white;
}

.BUTTON.signup_button {
    background: transparent !important;
    border-color: white;
    margin-top: 10px;
    color: white;
}

.BUTTON.fb_button {
    border-color: 0px;
    margin-top: 10px;

}

.BUTTON.google_button {
    border-color: 0px;
    margin-top: 10px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

@media only screen and (max-width: 600px) {

    .signin-text {
        margin-top: 13px;
        margin-bottom: 0px;
    }
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.sign-up-container {
    left: 0;
    width: 50%;
    z-index: 1;
    opacity: 0;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 50;
}

.overlay {
    background: var(--overlay-color);
    background: linear-gradient(to left, var(--overlay-gradiant1), var(--overlay-gradiant2)) no-repeat 0 0 / cover;
    color: white;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-panel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    width: 50%;
    text-align: center;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;

}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.overlay-left {
    transform: translateX(-20%);
}


/* Animation */

/* Move the signin to the right */
.container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

/*Move overlay to the left*/
.container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

/* Bring sign up over sign in*/
.container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

/* Move overlay back to the right*/

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.forgot {
    margin-top: 20px;

    margin-bottom: 20px;

}

@media only screen and (max-width: 600px) {

    .forgot {
        margin-top: 10px;

    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


form header {
    margin: 0 0 20px 0;
}

form header div {
    font-size: 90%;
    color: #999;
}

form header h2 {
    margin: 0 0 5px 0;
}

form>div {
    clear: both;
    overflow: hidden;
    padding: 1px;
    /* margin: 0 0 10px 0; */
}

.formdiv {
    clear: both;
    overflow: hidden;
    padding: 1px;
    margin: 0 0 10px 0;
}


form>div>label,
legend {
    width: 25%;
    float: left;
    padding-right: 10px;
}

.formlabel {
    width: 25%;
    float: left;
    padding-right: 10px;
}





/* input[type=text],
input[type=email],
input[type=url],
input[type=number],
input[type=password],

textarea,
select {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #144E82;
    background-color: #eee;
} */
.inputform {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #144E82;
    background-color: #eee;
}

.inputtextsize {
    width: 50%;
    background-color: white;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
textarea:focus {
    outline: 0;
    border-color: #144E82;
}

@media (max-width: 600px) {
    form>div {
        margin: 0 0 15px 0;
    }

    .formdiv {
        margin: 0 0 15px 0;
    }

    form>div>label,
    legend {
        width: 100%;
        float: none;
        margin: 0 0 5px 0;
    }

    .formlabel {
        width: 100%;
        float: none;
        margin: 0 0 5px 0;
    }

    form>div>div {
        width: 100%;
        float: none;
    }


    .inputtextsize {
        width: 100%;
    }
}

/* @media (min-width: 1200px) {

        form>div>label,
        legend {
            text-align: right;
        }
    } */

.primary-btn {
    line-height: 42px;
    padding-left: 40px;
    padding-right: 40px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    outline: none !important;
}

@media only screen and (max-width:600px) {
    .primary-btn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.primary-btn:hover {
    background-color: #144E82;
    color: white;
}

.primary-btn:active {
    box-shadow: 0 0 20px #144E82;
    transform: scale(1.1);
}

.deliver-btn {
    width: 100%;
    border: 1px solid #144E82;
    text-transform: uppercase;
    /* border-radius: 25px; */
    outline: 0;
    color: #144E82;
    text-align: center;
    font-size: 12px;
    line-height: 29px;
    display: block;
    white-space: nowrap;
    background-color: transparent;
    margin: 0;
    padding: 0 10px 0 11px;
    margin-top: 10px;
    outline: none !important;
    font-weight: 500;
}

.deliver-btn:hover {
    background-color: #144E82;
    color: white;
}

.deliver-btn:active {
    box-shadow: 0 0 20px #144E82;
    transform: scale(1.1);
}

.order-btn {
    /* width: 100%; */
    border: 1px solid #144E82;
    text-transform: uppercase;
    /* border-radius: 25px; */
    outline: 0;
    color: #144E82;
    text-align: center;
    font-size: 12px;
    line-height: 29px;
    display: block;
    white-space: nowrap;
    background-color: transparent;
    margin: 0;
    padding: 0 10px 0 11px;
    margin-top: 10px;
    outline: none !important;

}

.order-btn:hover {
    background-color: #144E82;
    color: white;
}

.text {
    margin: 0;
    font-weight: initial;
}

.desc {
    font-weight: bold;
}

.text-color {
    font-size: 90%;
    color: #999;
}

.link-color {
    color: #144E82 !important;

}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 4px;
}

.flex-items {
    /* -ms-flex: 24%;
        flex: 24%;
        max-width: 24%; */
    -ms-flex: 27.33%;
    flex: 27.33%;
    max-width: 27.33%;
    margin-right: 3%;
    margin-left: 3%;
    /* border: 1px solid black; */
    /* border: 1px solid black; */
    /* border-radius: 10px; */
    margin-top: 24px;
    /* margin: 0; */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

/* @media screen and (max-width: 1500px) {
.flex-items{
-ms-flex: 19%;
flex: 19%;
max-width: 19%;
}
} */


/* @media screen and (max-width: 1000px) {
        .flex-items {
            -ms-flex: 32.33%;
            flex: 32.33%;
            max-width: 32.33%;
        }
    } */

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 900px) {
    .flex-items {
        -ms-flex: 44%;
        flex: 44%;
        max-width: 44%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .flex-items {
        -ms-flex: 94%;
        flex: 94%;
        max-width: 94%;
    }
}

.name-bold {
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 16px;
}

address {
    color: black;
    font-style: normal;
    margin-bottom: 0px !important;
}

.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 10px;
}

.grid-item1 {
    grid-column: 1/3;
}

.textsize {
    font-size: 28px;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .textsize {
        font-size: 21px;

    }
}

.textsize1 {
    font-size: 21px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 0px;
}

@media screen and (max-width: 600px) {
    .textsize1 {
        font-size: 18px;

    }
}

.orderbox {
    border: 1px solid #144E82;
    border-radius: 10px;
    /* font-weight: bold; */
    margin-bottom: 20px;
}

.flex-ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    list-style: none;
    justify-content: space-between;
    padding-left: 0px;
    padding: 10px;
    background-color: rgba(245, 238, 238, 0.61);
    margin-bottom: 0px;
}

.hrmargin {
    margin-top: 0px !important;
}

.grid-ordercontainer {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-gap: 5px;
    padding: 10px;
    background-color: rgba(245, 238, 238, 0.61);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

@media only screen and (max-width:600px) {
    .grid-ordercontainer {
        grid-template-columns: auto;
    }
}

.grid-orderitem1 {
    grid-column: 1/3;
}

.grid-orderitem2 {
    justify-self: end;
}

.grid-orderitem4 {
    justify-self: center;
}

.grid-orderitem5 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .grid-orderitem1 {
        grid-column: 1/1;
    }

    .grid-orderitem2 {
        justify-self: start;
    }

    .grid-orderitem4 {
        justify-self: start;
    }
}

.grid-ordercontainer1 {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-gap: 5px;
    padding: 10px;
    min-height: 100px;

}

@media only screen and (max-width:600px) {
    .grid-ordercontainer1 {
        grid-template-columns: auto;
    }
}

.grid-orderitem7 {
    justify-self: center;
}

.grid-orderitem8 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .grid-orderitem7 {
        justify-self: start;
    }

    .grid-orderitem8 {
        justify-self: start;
    }
}

.orderdetailbox {
    border: 1px solid #144E82;
    border-radius: 10px;
    background-color: rgba(245, 238, 238, 0.61);
    padding: 10px;
    margin-bottom: 20px;
}

.grid-orderdetailcontainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    margin-bottom: 10px;
}

@media only screen and (max-width:600px) {
    .grid-orderdetailcontainer {
        grid-template-columns: auto;
    }
}

.grid-orderdetailitem2 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .grid-orderdetailitem2 {
        justify-self: start;
    }
}

.grid-orderdetailcontainer1 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    border: 1px solid #eee;
    grid-gap: 10px;
}

.grid-orderdetailitem3 {
    /* grid-row: 1/3; */
    background-color: white;
    /* padding-top: 15px; */

}

.grid-orderdetailitem4 {
    background-color: white;
    justify-self: start;
    width: 100%;
}

.grid-orderdetailitem5 {
    background-color: white;
    justify-self: start;
    width: 100%;
    /* padding: 10px;
    text-align: end; */
}

.grid-orderdetailitem6 {
    /* background-color: white; */
    justify-self: end;
}

.ul-orderdetail {
    list-style: none;
    padding: 10px;
    margin-bottom: 0px;
}

.ul-orderdetail1 {
    list-style: none;
    padding: 10px;
    margin-bottom: 0px;
}

@media only screen and (max-width:600px) {
    .ul-orderdetail1 {
        padding-top: 0px;
    }

    .ul-orderdetail {
        padding-bottom: 0px;
    }
}

.ul-orderdetail4 {
    list-style: none;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 4fr 3fr 3fr;
    padding: 10px;
}

.li-orderdetailitem2 {
    justify-self: end;
}

.li-orderdetailitem3 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .ul-orderdetail4 {

        flex-direction: column;


    }
}

.flex-orderdetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (max-width:600px) {
    .flex-orderdetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.margintophrline {
    margin-top: 24px;
}

.margintopflex {
    margin-top: 10px;
}

.flexrow {
    display: flex;
    flex-direction: row;
}

.flexheading {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 992px) {
    .flexheading {
        display: flex;
        flex-direction: column;
    }
}

.marginrightdate {
    margin-right: 20px;
}

.flexcontainer {
    display: flex;
    justify-content: space-between;
}

.logoutbtn {
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;

    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    /* margin-bottom: 20px; */
    outline: none !important;
    float: right;
}

.backbtn {
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;

    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    outline: none !important;
    float: right;
}

@media only screen and (max-width:600px) {
    .logoutbtn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.logoutbtn:hover {
    background-color: #144E82;
    color: white;
}

.backbtn:hover {
    background-color: #144E82;
    color: white;
}

.marginbottomorderdetail {
    margin-bottom: 4px;
}

.orderdetailbold {
    font-weight: bold;
}

.center {
    text-align: center;
}

.widthimage {
    width: 100px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 10px;
}

.text1 {
    text-align: center;
}

.id {
    width: 70%;
    border: 1px solid rgba(245, 238, 238, 0.61);
    /* text-transform: uppercase; */
    /* border-radius: 25px; */
    outline: 0;
    color: #144E82;
    text-align: center;
    font-size: 12px;
    line-height: 29px;
    display: block;
    /* white-space: nowrap; */
    background-color: transparent;
    margin: 0;
    padding: 10px 10px 10px 11px;
    margin-top: 10px;
    outline: none !important;
    font-weight: 500;
    background-color: rgba(245, 238, 238, 0.61);
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    overflow-wrap: break-word;

}

@media(max-width:800px) {
    .id {
        width: 100%;
    }

    .text1 {
        font-size: 20px;
    }
}

h6 {
    margin-bottom: 0px !important;
}

.idsize {
    font-size: 20px;


}

.view-btn {
    line-height: 42px;
    padding-left: 40px;
    padding-right: 40px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    outline: none !important;
    margin: 0 auto;
    display: flex;
}

@media only screen and (max-width:600px) {
    .view-btn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.view-btn:hover {
    background-color: #144E82;
    color: white;
}

.view-btn:active {
    box-shadow: 0 0 20px #144E82;
    transform: scale(1.1);
}

.break-word {
    word-wrap: break-word;
}

.margin-top {
    margin-top: 50px;
}

.normal {
    font-weight: normal;
}

.cus-name {
    font-size: 20px;
    margin-top: 8px;
}

@media(max-width:600px) {
    .cus-name {
        font-size: 10px;
    }
}

.grid-header-container {
    display: grid;
    grid-template-columns: 8fr auto auto;
    grid-gap: 10px;
}

/* .padding{
    padding: 20px 10%;
  }
  .bgcolor{
      background-color: #144E82;
  } */
.logowidth {
    width: 200px;
}

@media(max-width:600px) {
    .logowidth {
        width: 100px;
    }

    .grid-header-container {

        grid-template-columns: auto auto;

    }

    .grid-header-item {
        grid-column: 1/3;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.margintopdrop {
    margin-top: 5px;
}

.box {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.box select {


    margin: 5px 0px;
    padding: 10px 10px;
    /* width: 270px; */
    width: 100%;
    /* height: 34px; */
    color: #144E82;
    border: 1px solid #144E82;
    background: #eee;
    outline: none;
    border-radius: 5px;
}

.box:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
    padding: 30px;
}

.flexcontainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);

}

.dropdownbox {
    position: fixed;
    background: white;
    width: 40%;
    height: 70%;
    top: 50%;
    left: 150%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border-top-left-radius: 200px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    padding: 40px;
}

.generatebtn {
    border: 1px solid #144E82;
    background: transparent;
    border-radius: 5px;
    font-size: 15px;
    color: #144E82;
    padding: 5px 10px;
    width: 100px;
    font-weight: bold;
    outline: none !important;
    float: right;

}

.generatebtn:hover {
    background-color: #144E82;
    color: white;
    font-weight: bold;
}

.MuiDialog-paper {
    margin: 0px !important;
}

.widthinputbox {
    width: 100%;
}

.padding {
    padding: 10px;
    border-radius: 5px;
}

.sizetitle {
    font-size: 30px;
}

.paddingbottomdomain {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.loaderloginbtn {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid rgba(0, 0, 0, 0.15);
    width: 16px;
    height: 16px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-left: 45px;

}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.margintop {
    margin-top: 20px !important;
}

.posotionmelzologo {
    position: fixed;
    top: 20px;
    left: 20px;
}

.widthmelzologo {
    width: 30px;
}

.MuiDialog-paperWidthSm {
    width: 600px;
}

@media(max-width:600px) {

    .MuiDialog-paperWidthSm {
        width: 400px;
    }
}

.scrollbar-style::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
}

.scrollbar-style::-webkit-scrollbar {
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
}

.scrollbar-style::-webkit-scrollbar-thumb {
    background-color: rgba(240, 240, 240, 0.3);
}

.vert-scrollbar-style::-webkit-scrollbar-thumb {
    background-color: #546e7a;
    /*rgba(240, 240, 240, 0.3);*/
}

.vert-scrollbar-style::-webkit-scrollbar-track {
    background-color: #949494;
    /*rgba(0, 0, 0, 0);*/
}

.vert-scrollbar-style::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
}

#pie {
    height: 440px;
}



.dashboardbtn {
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
    font-weight: bold;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    outline: none !important;
    box-shadow: 0 0 10px #144E82;
    background-color: #144E82;
    color: white;
    border: 0;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}


@media only screen and (max-width:600px) {
    .dashboardbtn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.dashboardbtn:hover {
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
}

.downloadbtn {

    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 47px;
    background-color: #144E82;
    border: 1.5px solid #144E82;
    height: 50px;
    width: 250px;
    text-transform: uppercase;
    background-image: linear-gradient(to right, #fff, #fff 50%, #144E82 50%);
    background-size: 210% 100%;
    background-position: 99%;
    outline: none !important;
}

/* .downloadbtn:after {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 5px;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    content: "";
} */

.downloadbtn:hover {
    transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
    background-position: 0%;
    color: #144E82;}

/* .downloadbtn:hover:after {
    width: 100%;
    background: rgba(0, 0, 0, 0.02);
} */








.marginchart {
    margin-top: 10px;
    margin-bottom: 40px;
}

@media(max-width:600px) {
    .marginchart {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .marginchartcol {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.fontweight {
    font-weight: bold;
    text-decoration: underline;
}

.fontfamily {
    font-family: 'bold', sans-serif;

}

body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: #144E82;
    border-radius: 20px;
    border: 3px solid transparent;
}

#chart {
    height: 440px;
}

.schedule-tab {
    /* height: 140px; */
    display: flex;
  }
  
  .schedule-tab__item {
    width: 9.09%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    border-left: unset;
    position: relative;
    padding: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .schedule-tab__item:first-child {
    border-left: 1px solid #e6e6e6;
  }
  
  .schedule-tab__item h2 {
    transition: all 0.3s ease;
    font-size: 10px;
    margin-top: 2px;
  }
  
  .schedule-tab__item p {
    transition: all 0.3s ease;
    font-size: 14px;
    color: #144E82;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 0;
  }
  
  @media(max-width:990px) {
    .schedule-tab__item p {
      font-size: 7px;
    }
    .schedule-tab__item h2 {
      font-size: 8px;
    }
  }
  
  .schedule-tab__item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #144E82;
    z-index: -1;
    /* IE 9 */
    /* Safari 3-8 */
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition-property: transform;
    transition: 300ms ease-out;
  }
  
  .schedule-tab__item:hover::before {
    /* IE 9 */
    /* Safari 3-8 */
    transform: scaleY(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
  
  .schedule-tab__item:hover h2, .schedule-tab__item:hover p {
    color: #ffffff;
  }
  .schedule-tab__item:hover{
    background-color: #144E82;

  }
  .schedule-tab .tab-active {
    background-color: #144E82;
  }
  
  .schedule-tab .tab-active h2, .schedule-tab .tab-active p {
    color: #ffffff;
  }
  @media (max-width: 600px) {
    .schedule-table {
      padding-top: 40px;
    }
    /* .schedule-tab {
      height: 100px;
    } */
    .schedule-tab h2 {
      font-size: 25px;
    }
    .schedule-tab p {
      font-size: 14px;
    }
  }
  @media (max-width: 576px) {
    .schedule-table .row:first-child .schedule-table__time {
      padding-top: 15px;
    }
    .schedule-table__time {
      border-right: unset;
      padding: 30px 0 15px 0;
    }
    .schedule-table__event {
      width: 100%;
      padding: 0 0 15px 0;
      border: unset;
      margin: 0;
    }
    .schedule-table__speaker {
      padding: 0;
    }
    .break-time {
      height: 170px;
    }
    .break-time__coffee {
      padding: 0 0 0 15px;
      text-align: center;
    }
    .break-time__coffee h3 {
      margin: 0;
      line-height: 20px;
    }
    .schedule-tab {
      border-top: 1px solid #e6e6e6;
      flex-wrap: wrap;
      height: auto;
    }
    .schedule-tab .schedule-tab__item {
      width: 100%;
      height: 80px;
      border-left: 1px solid #e6e6e6;
      border-top: unset;
    }
  }