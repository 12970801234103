@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

.navbar-prop {
    box-shadow: 0 0 2rem 0 rgba(53, 64, 82, 0.1);
    height: 70px;
    position: fixed;
    width: 85%;
    /* top: 0px; */
    z-index: 100;
    /* left: 0px; */
    background: #fff;
}


@media only screen and (max-width: 992px) {
    .navbar-prop {
        width: 100%;
        top: 90px;
    }
}

/* @media only screen and (max-width: 740px) {
    .navbar-search-props {
        margin-left: 0;
        top: 5px;

    }
} */

.navbar-search-prop {
    width: 200px;
    height: 35px;
    border: 0px !important;
    border-bottom: 1px solid #e6e3e3 !important;
    font-family: 'Nunito Sans', sans-serif;
    padding: .375rem .75rem;
}

@media only screen and (max-width: 740px) {
    .navbar-search-prop {
        width: 150px;
        /* margin-left: 10px; */
    }
}

.navbar-search-prop:focus {
    box-shadow: none;
}

.navbar-brand-prop {
    margin: 10px;
}

@media(max-width:600px) {
    .navbar-brand-prop {
        display: none;
    }
}

.dropdown-options-prop {
    background-color: #F4F8FB !important;
    border-color: #fff !important;
    /* margin-right: 10px; */
}

.dropdown-item {
    margin: 0;
}

.dropdown-item:active {
    background-color: #f2f5ff !important;
}

.dropdown-item-prop {
    color: black;
}

.dropdown-image-prop {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    filter: drop-shadow(2px 4px 6px gray);
    -webkit-filter: drop-shadow(2px 4px 6px gray);
}

.user-avatar-prop {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    filter: drop-shadow(2px 4px 6px gray);
    -webkit-filter: drop-shadow(2px 4px 6px gray);
}

.navbar-right-props {
    display: inline-flex;
    justify-content: flex-end;
    position: absolute;
    right: 2%;
    align-items: center;
}

@media only screen and (max-width:600px) {
    .navbar-right-props {
        top: 0px;
    }
}

.marginrightinput {
    margin-right: 20px;
}

.margintoptable {
    margin-top: 20px;
}

.countlabel {
    font-size: 20px;
    font-weight: bold;
}

.numbers {
    font-size: 20px;
    color: black;
}

.marginbottomform {
    margin-bottom: 10px;
}

.flexcontainer {
    display: flex;
}

.table {
    margin-top: 1rem;
}

.table thead th {
    color: #144E82;
}

.marginrightlabel {
    margin-right: 20px;
}


.overflowtableanalysis {
    overflow-x: auto;
    height: 700px;
}

.overflowtableproject {
    overflow-x: auto;
}

.dropdown-menu {
    right: 0 !important;
    float: right !important;
    left: auto !important;
}

.dropdown-toggle::after {
    background-color: #F4F8FB !important;
    border-right: 0em solid transparent !important;
    border-left: 0em solid transparent !important;
}

.hrlinemenu {
    margin-top: 0px;
    margin-bottom: 0px;
}