@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');
.content {
    padding: 50px;
    background-color: #F4F8FB;
    padding-top: 100px;
    height: 100%;
    overflow-y: auto;
}

@media only screen and (max-width: 992px) {
    .content {
        padding-top: 170px;
    }
}

.parent-card-list {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-bottom: 30px;
}

.card-item {
    width: 300px;
    height: 132px;
    background-color: #fff;
    margin: 10px;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05)
}

.inner-card-item {
    margin: 40px;
}

.inner-card-item h3 {
    display: inline-flex;
    /* position: absolute; */
    font-weight: 400;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.inner-card-item p {
    text-align: center;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color: #495057;
    font-weight: 400;
    margin-top: -10px;
}

@media only screen and (max-width: 804px) {
    .parent-card-list {
        justify-content: center;
    }
    .inner-card-item svg {
        margin-right: 40px;
    }
}

.daily-feed-card {
    background-color: #fff;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.daily-feed-header-props {
    padding: 20px;
    margin-bottom: 20px;
}

.daily-feed-header-title {
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color: #495057;
    font-weight: 400;
    size: 11px;
}

.daily-feed-brand {
    width: 50px;
    height: 15px;
    background-color: #5b7dff;
    position: absolute;
    right: 30px;
    top: 25px;
    border-radius: 5px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.daily-feed-parent {}

.daily-feed-inner {
    display: flex;
}

.daily-feed-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.daily-feed-text {
    color: #495057;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin: 10px;
}

.daily-feed-time {
    color: #495057;
    margin-left: 10px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
}

.daily-feed-daytime {
    color: #495057;
    margin-left: 10px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.daily-feed-button {
    background-color: #47BAC1;
    border-color: #47BAC1;
    color: #fff;
    height: 30px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.footer-props {
    display: flex;
    background: #fff;
    border: 1px solid #dee2e6;
    padding: 1rem 0.75rem;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.mobile-footer-item {
    display: none;
}

.footer-item-list {
    display: flex;
    padding-left: 20px;
    margin-bottom: 0px;
}

.footer-item-list li {
    list-style: none;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color: #495057;
    font-weight: 400;
    margin-right: 10px;
}

@media only screen and (max-width: 436px) {
    .footer-item-list {
        display: none;
    }
    .mobile-footer-item {
        display: flex;
        list-style: none;
        padding: 0px;
    }
    .footer-props {
        justify-content: center;
        right: 0px;
        /* padding: 10px 0px 0px; */
    }
}

.reset-button-props {
    background-color: orange;
    border-radius: 30px;
    width: 100px;
    color: #fff;
    margin-left: 20px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.datepicker-props {
    background-color: #fff;
    margin-bottom: 20px;
    transform: scale(0.95);
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.datePicker-header-title {
    padding: 10px 0px 0px 10px;
    font-style: italic
}

.datePicker-text-props {
    margin-right: 20px;
    text-align: center;
    font-style: italic;
    padding: 10px 0px 0px 10px;
}

.piechart-props {
    background-color: #fff;
    padding: 0px;
    transform: scale(0.95);
    margin-bottom: 20px;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.piechart-header-props {
    padding: 10px 0px 0px 10px;
    font-style: italic;
}

.lineChart-props {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.lineChart-header-props {
    font-style: italic;
}

.calendar-props {
    display: block !important;
}

.appointment-props {
    background-color: #fff;
    padding: 0px;
    transform: scale(0.95);
    margin-bottom: 20px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.appointment-header-props {
    padding: 10px 0px 0px 10px;
    font-style: italic;
}

.daily-appointment-props {
    background-color: #F4F8FB;
    height: 100px auto;
}

.daily-appointment-text {
    color: #495057;
    padding: 20px 0px 0px 20px;
    margin-bottom: 0px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 25px;
}

.daily-appointment-text-inner {
    color: #495057;
    padding: 10px 10px;
    margin-left: 10px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.appointment-activity-props {
    padding: 20px;
}

.activity-logo {
    border: 4px solid #47BAC1;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.activity-line {
    border-right: 2px solid #47BAC1;
    width: 10.5px;
    height: 100px;
    border-color: #47BAC1;
    display: inline-block;
}

.activity-text-header {
    position: fixed;
    margin: -20px 0px 0px 20px;
}

.activity-text {
    margin: 5px 20px 0px 20px;
    position: fixed;
    display: inline-block;
}

.activity-logo-inner {
    margin-top: -50px;
}