@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
:root {
    --main-bg-color: brown;
    --primary: rgb(127, 174, 65);
    --primary-t1: rgba(127, 174, 65, 0.85);
    --primary-t2: rgba(127, 174, 65, 0.65);
    --primary-t3: rgba(127, 174, 65, 0.45);
    --primary-t4: rgba(127, 174, 65, 0.25);
    /**/
    --secondary: rgb(212, 212, 212);
    --secondary-t1: rgba(212, 212, 212, 0.85);
    --secondary-t2: rgba(212, 212, 212, 0.65);
    --secondary-t3: rgba(212, 212, 212, 0.45);
    --secondary-t4: rgba(212, 212, 212, 0.25);
    /**/
    --secondary-dark: rgb(148, 148, 148);
    --secondary-dark-t1: rgb(148, 148, 148, 0.85);
    --secondary-dark-t2: rgb(148, 148, 148, 0.65);
    --secondary-dark-t3: rgb(148, 148, 148, 0.45);
    --secondary-dark-t4: rgb(148, 148, 148, 0.25);
    /**/
    --ui-primary: rgb(38, 50, 56);
    --ui-primary-t1: rgba(38, 50, 56, 0.85);
    --ui-primary-t2: rgba(38, 50, 56, 0.65);
    --ui-primary-t3: rgba(38, 50, 56, 0.45);
    --ui-primary-t4: rgba(38, 50, 56, 0.25);
    /**/
    --ui-secondary: rgb(84, 110, 122);
    --ui-secondary-t1: rgba(84, 110, 122, 0.85);
    --ui-secondary-t2: rgba(84, 110, 122, 0.65);
    --ui-secondary-t3: rgba(84, 110, 122, 0.45);
    --ui-secondary-t4: rgba(84, 110, 122, 0.25);
    /**/
    --ui-primary-dark: rgb(15, 22, 25);
    --ui-primary-dark-t1: rgba(15, 22, 25, 0.85);
    --ui-primary-dark-t2: rgba(15, 22, 25, 0.65);
    --ui-primary-dark-t3: rgba(15, 22, 25, 0.45);
    --ui-primary-dark-t4: rgba(15, 22, 25, 0.25);
    /**/
    --ui-danger: rgb(244, 67, 54);
    --ui-danger-t1: rgba(244, 67, 54, 0.85);
    --ui-danger-t2: rgba(244, 67, 54, 0.65);
    --ui-danger-t3: rgba(244, 67, 54, 0.45);
    --ui-danger-t4: rgba(244, 67, 54, 0.25);
  }
html,
body {
    height: 100%;
}

.sidebar-props {
    background-color: #BBBDBD;
    box-sizing: border-box;
    /* padding-top: 15px; */
    /* padding-left: 30px; */
    /* width: 100%; */
    height: 100%;
    /* padding: 10px; */
}

@media only screen and (max-width: 992px) {
    .sidebar-props {
        height: auto;
        /* padding: 10px; */
    }
}

.dashboard-props {
    padding: 0;
}

.main {
    position: absolute;
    height: 100%;
    width: 100%;
}

.heightrow {
    height: 100%;
}
:root {
    --parent-color: rgba(0, 0, 0, 0.1);
}

.sidebar-header {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* position: fixed; */
}

@media only screen and (max-width: 992px) {
    .sidebar-header {
        flex-direction: row;
    }
}

.header-text {
    font-size: 20px;
    margin: 10px;
    color: #fff;
    /* margin-top: 0px; */
}

.sidebar-menu-title {
    color: #ACB5BD;
    font-size: 14px;
    padding-left: 10px;
}

@media only screen and (min-width: 992px) {

    .sidebar-menu {
        /* position: fixed; */
        top: 15%;
        width: 100%;
    }

    /* 
    .sidebar-header {
        position: fixed;
    } */
}

@media only screen and (max-width: 992px) {
    .sidebar-menu {
        display: none;
    }
}

.parent-menu {
    display: flex;
    width: 100% !important;
    text-align: left !important;
}

.parent-menu:hover {
    background-color: rgba(0, 0, 0, 0.1);
    background-color: var(--parent-color);
}

.parent-menu-text {
    color: #ffffff;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 21px;
}

.parent-menu:hover {
    text-decoration: none !important;
}

.child-menu {
    background-color: #310d2b;
}

.child-menu-item-props {
    background-color: transparent !important;
    border-color: transparent !important;
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
    text-align: left !important;
    /* padding: 10px 0px 10px 60px !important; */
    color: #4D4D4D !important;
    font-size: 14px !important;
    margin: 0px;
}

.child-menu-item-props:focus {
    box-shadow: none !important;
}

.child-menu-item-props:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
    background-color: var(--parent-color) !important;
}

.icon-title-props {
    color: #C1C7CE;
}

.selected-sidebar-item {
    background-color: rgba(0, 0, 0, 0.1) !important;
    background-color: var(--parent-color) !important;
    color: #fff !important;
}

.widthlogo {
    width: 100%;
    /* height: 40px; */
}

@media(max-width:600px) {
    .widthlogo {
        width: 150px;
    }
}

.widthmenu {
    width: 30px;
    /* height: 40px; */
}

.paddingright {
    padding-right: 0px;
}

.hrline {
    border-top: 1px solid white;
}

@media(max-width:601px) {
    .compdisplay {
        display: none;
    }
}

.positionmenu {
    display: none;
}

@media only screen and (max-width: 992px) {
    .sidebar-menu {
        display: none;
    }

    .positionmenu {
        display: block;
        position: fixed;
        top: 20px;
        left: 10px;
    }
}

.overlaymenu {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.menuposition {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.flexmenu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    font-weight: bold;
    top: 16px;
    /* right: 10px; */
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    list-style-type: none;
    padding: 20px;
    width: 100%;
}

.menubg {
    width: 80%;
    height: 100%;
    /* right: -20%; */
    position: fixed;
    background: #BBBDBD;
    z-index: 3;
    display: none;
}

.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.flexmenulist {
    margin-top: 20px;
    color: #36cca5;

}

.openmenuanimation {
    -webkit-animation: lefthtoleftmenu 0.5s forwards;
            animation: lefthtoleftmenu 0.5s forwards;

}

@-webkit-keyframes lefthtoleftmenu {
    0% {
        left: -150%;
    }

    100% {
        left: 0%;
    }
}

@keyframes lefthtoleftmenu {
    0% {
        left: -150%;
    }

    100% {
        left: 0%;
    }
}

.closmenuanimation {
    -webkit-animation: lefttorightmenu 1s forwards;
            animation: lefttorightmenu 1s forwards;
}

@-webkit-keyframes lefttorightmenu {
    0% {
        left: 0%;
    }

    100% {
        left: -150%;
    }
}

@keyframes lefttorightmenu {
    0% {
        left: 0%;
    }

    100% {
        left: -150%;
    }
}

.sidebar-props {
    height: auto;
    width: 16%;
    position: fixed;
    top: 0px;
    z-index: 1000;
    /* padding: 10px; */
}

@media only screen and (max-width: 992px) {
    .sidebar-props {
        height: auto;
        position: fixed;
        top: 0px;
        z-index: 1000;
        width: 100%;
        /* padding: 10px; */
    }
}

.widthlisticon {
    width: 18px;
    height: 16px;
}

.positionlogo {
    /* position: fixed; */
    /* top: 20px;
    left: 20px; */
    background-color: white;
    width: 60px;
    height: 60px;
    z-index: 100;
    padding: 2px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;

}



.flexlogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-link:hover {
    color: transparent !important;
}

.btn-link {
    color: transparent !important;
}

.btn.focus,
.btn:focus {
    box-shadow: 0 0 0 0 !important;
}
.navbar-prop {
    box-shadow: 0 0 2rem 0 rgba(53, 64, 82, 0.1);
    height: 70px;
    position: fixed;
    width: 85%;
    /* top: 0px; */
    z-index: 100;
    /* left: 0px; */
    background: #fff;
}


@media only screen and (max-width: 992px) {
    .navbar-prop {
        width: 100%;
        top: 90px;
    }
}

/* @media only screen and (max-width: 740px) {
    .navbar-search-props {
        margin-left: 0;
        top: 5px;

    }
} */

.navbar-search-prop {
    width: 200px;
    height: 35px;
    border: 0px !important;
    border-bottom: 1px solid #e6e3e3 !important;
    font-family: 'Nunito Sans', sans-serif;
    padding: .375rem .75rem;
}

@media only screen and (max-width: 740px) {
    .navbar-search-prop {
        width: 150px;
        /* margin-left: 10px; */
    }
}

.navbar-search-prop:focus {
    box-shadow: none;
}

.navbar-brand-prop {
    margin: 10px;
}

@media(max-width:600px) {
    .navbar-brand-prop {
        display: none;
    }
}

.dropdown-options-prop {
    background-color: #F4F8FB !important;
    border-color: #fff !important;
    /* margin-right: 10px; */
}

.dropdown-item {
    margin: 0;
}

.dropdown-item:active {
    background-color: #f2f5ff !important;
}

.dropdown-item-prop {
    color: black;
}

.dropdown-image-prop {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    filter: drop-shadow(2px 4px 6px gray);
    -webkit-filter: drop-shadow(2px 4px 6px gray);
}

.user-avatar-prop {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    filter: drop-shadow(2px 4px 6px gray);
    -webkit-filter: drop-shadow(2px 4px 6px gray);
}

.navbar-right-props {
    display: inline-flex;
    justify-content: flex-end;
    position: absolute;
    right: 2%;
    align-items: center;
}

@media only screen and (max-width:600px) {
    .navbar-right-props {
        top: 0px;
    }
}

.marginrightinput {
    margin-right: 20px;
}

.margintoptable {
    margin-top: 20px;
}

.countlabel {
    font-size: 20px;
    font-weight: bold;
}

.numbers {
    font-size: 20px;
    color: black;
}

.marginbottomform {
    margin-bottom: 10px;
}

.flexcontainer {
    display: flex;
}

.table {
    margin-top: 1rem;
}

.table thead th {
    color: #144E82;
}

.marginrightlabel {
    margin-right: 20px;
}


.overflowtableanalysis {
    overflow-x: auto;
    height: 700px;
}

.overflowtableproject {
    overflow-x: auto;
}

.dropdown-menu {
    right: 0 !important;
    float: right !important;
    left: auto !important;
}

.dropdown-toggle::after {
    background-color: #F4F8FB !important;
    border-right: 0em solid transparent !important;
    border-left: 0em solid transparent !important;
}

.hrlinemenu {
    margin-top: 0px;
    margin-bottom: 0px;
}
:root {
    /*Customize colors*/
    --overlay-color: #144E82;
    --button-color: #144E82;
    --button-border-color: #144E82;
    --overlay-gradiant1: rgb(21, 86, 133);
    --overlay-gradiant2: #144E82;
}





* {
    box-sizing: border-box;
}

body {
    /* font-family: 'Montserrat', sans-serif !important; */
    background-color: #eee;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    /* height: 100vh; */
    /* margin: 10px 0 50px; */
    /* padding: 20px 10%; */
    font-family: 'regular', sans-serif !important;
    color: #144E82 !important;
    overflow-x: hidden;
}

.text {
    font-weight: bold !important;
    font-size: 30px !important;
    margin: 0;

}

p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}

a {
    color: #333 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    margin: 50px 0;
}

.bodyheight {
    height: 100vh;
}

.container {
    background: white;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px !important;
    max-width: 100%;
    min-height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* top: 50%; */
    /* margin-top: -250px; */
    /* height: 100vh; */
}

@media only screen and (max-width: 600px) {
    .container {
        width: 375px !important;
    }
}

.form-container form {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.social-container {
    margin: 21px 0;
}

.social-container a {
    border: 1px solid #ddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

.form-container input {
    background: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;

    border-radius: 5px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 13px;
}

@media only screen and (max-width: 600px) {

    .form-container input {
        width: 120px;
    }
}

.form-container input:focus {
    outline: none;
}

.BUTTON {
    border-radius: 20px !important;
    border: 1px solid #144E82;
    border: 1px solid var(--button-border-color);
    background: transparent;
    /* background: linear-gradient(to right, var(--overlay-gradiant1), var(--overlay-gradiant2)) no-repeat 0 0 / cover; */
    color: #144E82;
    font-size: 12px !important;
    font-weight: bold;
    padding: 12px 15px;
    letter-spacing: 1px;
    text-transform: uppercase !important;
    -webkit-transition: -webkit-transform 80ms ease-in;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
    outline: none !important;
    width: 232px !important;
    margin-bottom: 20px;

}

.BUTTON:hover {
    background-color: #144E82;
    color: white;
}

@media only screen and (max-width: 600px) {
    .BUTTON {
        width: 150px !important;
    }
}

.BUTTON:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

.BUTTON:focus {
    outline: none;
}

.BUTTON.login_button {
    background: transparent !important;
    border-color: white;
    margin-top: 10px;
    color: white;
}

.BUTTON.signup_button {
    background: transparent !important;
    border-color: white;
    margin-top: 10px;
    color: white;
}

.BUTTON.fb_button {
    border-color: 0px;
    margin-top: 10px;

}

.BUTTON.google_button {
    border-color: 0px;
    margin-top: 10px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}

@media only screen and (max-width: 600px) {

    .signin-text {
        margin-top: 13px;
        margin-bottom: 0px;
    }
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.sign-up-container {
    left: 0;
    width: 50%;
    z-index: 1;
    opacity: 0;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
    z-index: 50;
}

.overlay {
    background: #144E82;
    background: var(--overlay-color);
    background: -webkit-gradient(linear, right top, left top, from(rgb(21, 86, 133)), to(#144E82)) no-repeat 0 0 / cover;
    background: linear-gradient(to left, rgb(21, 86, 133), #144E82) no-repeat 0 0 / cover;
    background: -webkit-gradient(linear, right top, left top, from(var(--overlay-gradiant1)), to(var(--overlay-gradiant2))) no-repeat 0 0 / cover;
    background: linear-gradient(to left, var(--overlay-gradiant1), var(--overlay-gradiant2)) no-repeat 0 0 / cover;
    color: white;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-panel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    width: 50%;
    text-align: center;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;

}

.overlay-right {
    right: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.overlay-left {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
}


/* Animation */

/* Move the signin to the right */
.container.right-panel-active .sign-in-container {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}

/*Move overlay to the left*/
.container.right-panel-active .overlay-container {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

/* Bring sign up over sign in*/
.container.right-panel-active .sign-up-container {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

/* Move overlay back to the right*/

.container.right-panel-active .overlay {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
}

.container.right-panel-active .overlay-left {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
}

.forgot {
    margin-top: 20px;

    margin-bottom: 20px;

}

@media only screen and (max-width: 600px) {

    .forgot {
        margin-top: 10px;

    }
}

* {
    box-sizing: border-box;
}


form header {
    margin: 0 0 20px 0;
}

form header div {
    font-size: 90%;
    color: #999;
}

form header h2 {
    margin: 0 0 5px 0;
}

form>div {
    clear: both;
    overflow: hidden;
    padding: 1px;
    /* margin: 0 0 10px 0; */
}

.formdiv {
    clear: both;
    overflow: hidden;
    padding: 1px;
    margin: 0 0 10px 0;
}


form>div>label,
legend {
    width: 25%;
    float: left;
    padding-right: 10px;
}

.formlabel {
    width: 25%;
    float: left;
    padding-right: 10px;
}





/* input[type=text],
input[type=email],
input[type=url],
input[type=number],
input[type=password],

textarea,
select {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #144E82;
    background-color: #eee;
} */
.inputform {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #144E82;
    background-color: #eee;
}

.inputtextsize {
    width: 50%;
    background-color: white;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
textarea:focus {
    outline: 0;
    border-color: #144E82;
}

@media (max-width: 600px) {
    form>div {
        margin: 0 0 15px 0;
    }

    .formdiv {
        margin: 0 0 15px 0;
    }

    form>div>label,
    legend {
        width: 100%;
        float: none;
        margin: 0 0 5px 0;
    }

    .formlabel {
        width: 100%;
        float: none;
        margin: 0 0 5px 0;
    }

    form>div>div {
        width: 100%;
        float: none;
    }


    .inputtextsize {
        width: 100%;
    }
}

/* @media (min-width: 1200px) {

        form>div>label,
        legend {
            text-align: right;
        }
    } */

.primary-btn {
    line-height: 42px;
    padding-left: 40px;
    padding-right: 40px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    outline: none !important;
}

@media only screen and (max-width:600px) {
    .primary-btn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.primary-btn:hover {
    background-color: #144E82;
    color: white;
}

.primary-btn:active {
    box-shadow: 0 0 20px #144E82;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.deliver-btn {
    width: 100%;
    border: 1px solid #144E82;
    text-transform: uppercase;
    /* border-radius: 25px; */
    outline: 0;
    color: #144E82;
    text-align: center;
    font-size: 12px;
    line-height: 29px;
    display: block;
    white-space: nowrap;
    background-color: transparent;
    margin: 0;
    padding: 0 10px 0 11px;
    margin-top: 10px;
    outline: none !important;
    font-weight: 500;
}

.deliver-btn:hover {
    background-color: #144E82;
    color: white;
}

.deliver-btn:active {
    box-shadow: 0 0 20px #144E82;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.order-btn {
    /* width: 100%; */
    border: 1px solid #144E82;
    text-transform: uppercase;
    /* border-radius: 25px; */
    outline: 0;
    color: #144E82;
    text-align: center;
    font-size: 12px;
    line-height: 29px;
    display: block;
    white-space: nowrap;
    background-color: transparent;
    margin: 0;
    padding: 0 10px 0 11px;
    margin-top: 10px;
    outline: none !important;

}

.order-btn:hover {
    background-color: #144E82;
    color: white;
}

.text {
    margin: 0;
    font-weight: normal;
    font-weight: initial;
}

.desc {
    font-weight: bold;
}

.text-color {
    font-size: 90%;
    color: #999;
}

.link-color {
    color: #144E82 !important;

}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 4px;
}

.flex-items {
    /* -ms-flex: 24%;
        flex: 24%;
        max-width: 24%; */
    flex: 27.33% 1;
    max-width: 27.33%;
    margin-right: 3%;
    margin-left: 3%;
    /* border: 1px solid black; */
    /* border: 1px solid black; */
    /* border-radius: 10px; */
    margin-top: 24px;
    /* margin: 0; */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

/* @media screen and (max-width: 1500px) {
.flex-items{
-ms-flex: 19%;
flex: 19%;
max-width: 19%;
}
} */


/* @media screen and (max-width: 1000px) {
        .flex-items {
            -ms-flex: 32.33%;
            flex: 32.33%;
            max-width: 32.33%;
        }
    } */

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 900px) {
    .flex-items {
        flex: 44% 1;
        max-width: 44%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .flex-items {
        flex: 94% 1;
        max-width: 94%;
    }
}

.name-bold {
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 16px;
}

address {
    color: black;
    font-style: normal;
    margin-bottom: 0px !important;
}

.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 10px;
}

.grid-item1 {
    grid-column: 1/3;
}

.textsize {
    font-size: 28px;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .textsize {
        font-size: 21px;

    }
}

.textsize1 {
    font-size: 21px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 0px;
}

@media screen and (max-width: 600px) {
    .textsize1 {
        font-size: 18px;

    }
}

.orderbox {
    border: 1px solid #144E82;
    border-radius: 10px;
    /* font-weight: bold; */
    margin-bottom: 20px;
}

.flex-ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    list-style: none;
    justify-content: space-between;
    padding-left: 0px;
    padding: 10px;
    background-color: rgba(245, 238, 238, 0.61);
    margin-bottom: 0px;
}

.hrmargin {
    margin-top: 0px !important;
}

.grid-ordercontainer {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-gap: 5px;
    padding: 10px;
    background-color: rgba(245, 238, 238, 0.61);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

@media only screen and (max-width:600px) {
    .grid-ordercontainer {
        grid-template-columns: auto;
    }
}

.grid-orderitem1 {
    grid-column: 1/3;
}

.grid-orderitem2 {
    justify-self: end;
}

.grid-orderitem4 {
    justify-self: center;
}

.grid-orderitem5 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .grid-orderitem1 {
        grid-column: 1/1;
    }

    .grid-orderitem2 {
        justify-self: start;
    }

    .grid-orderitem4 {
        justify-self: start;
    }
}

.grid-ordercontainer1 {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-gap: 5px;
    padding: 10px;
    min-height: 100px;

}

@media only screen and (max-width:600px) {
    .grid-ordercontainer1 {
        grid-template-columns: auto;
    }
}

.grid-orderitem7 {
    justify-self: center;
}

.grid-orderitem8 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .grid-orderitem7 {
        justify-self: start;
    }

    .grid-orderitem8 {
        justify-self: start;
    }
}

.orderdetailbox {
    border: 1px solid #144E82;
    border-radius: 10px;
    background-color: rgba(245, 238, 238, 0.61);
    padding: 10px;
    margin-bottom: 20px;
}

.grid-orderdetailcontainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    margin-bottom: 10px;
}

@media only screen and (max-width:600px) {
    .grid-orderdetailcontainer {
        grid-template-columns: auto;
    }
}

.grid-orderdetailitem2 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .grid-orderdetailitem2 {
        justify-self: start;
    }
}

.grid-orderdetailcontainer1 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    border: 1px solid #eee;
    grid-gap: 10px;
}

.grid-orderdetailitem3 {
    /* grid-row: 1/3; */
    background-color: white;
    /* padding-top: 15px; */

}

.grid-orderdetailitem4 {
    background-color: white;
    justify-self: start;
    width: 100%;
}

.grid-orderdetailitem5 {
    background-color: white;
    justify-self: start;
    width: 100%;
    /* padding: 10px;
    text-align: end; */
}

.grid-orderdetailitem6 {
    /* background-color: white; */
    justify-self: end;
}

.ul-orderdetail {
    list-style: none;
    padding: 10px;
    margin-bottom: 0px;
}

.ul-orderdetail1 {
    list-style: none;
    padding: 10px;
    margin-bottom: 0px;
}

@media only screen and (max-width:600px) {
    .ul-orderdetail1 {
        padding-top: 0px;
    }

    .ul-orderdetail {
        padding-bottom: 0px;
    }
}

.ul-orderdetail4 {
    list-style: none;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 4fr 3fr 3fr;
    padding: 10px;
}

.li-orderdetailitem2 {
    justify-self: end;
}

.li-orderdetailitem3 {
    justify-self: end;
}

@media only screen and (max-width:600px) {
    .ul-orderdetail4 {

        flex-direction: column;


    }
}

.flex-orderdetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (max-width:600px) {
    .flex-orderdetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.margintophrline {
    margin-top: 24px;
}

.margintopflex {
    margin-top: 10px;
}

.flexrow {
    display: flex;
    flex-direction: row;
}

.flexheading {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 992px) {
    .flexheading {
        display: flex;
        flex-direction: column;
    }
}

.marginrightdate {
    margin-right: 20px;
}

.flexcontainer {
    display: flex;
    justify-content: space-between;
}

.logoutbtn {
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;

    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    /* margin-bottom: 20px; */
    outline: none !important;
    float: right;
}

.backbtn {
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;

    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    outline: none !important;
    float: right;
}

@media only screen and (max-width:600px) {
    .logoutbtn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.logoutbtn:hover {
    background-color: #144E82;
    color: white;
}

.backbtn:hover {
    background-color: #144E82;
    color: white;
}

.marginbottomorderdetail {
    margin-bottom: 4px;
}

.orderdetailbold {
    font-weight: bold;
}

.center {
    text-align: center;
}

.widthimage {
    width: 100px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 10px;
}

.text1 {
    text-align: center;
}

.id {
    width: 70%;
    border: 1px solid rgba(245, 238, 238, 0.61);
    /* text-transform: uppercase; */
    /* border-radius: 25px; */
    outline: 0;
    color: #144E82;
    text-align: center;
    font-size: 12px;
    line-height: 29px;
    display: block;
    /* white-space: nowrap; */
    background-color: transparent;
    margin: 0;
    padding: 10px 10px 10px 11px;
    margin-top: 10px;
    outline: none !important;
    font-weight: 500;
    background-color: rgba(245, 238, 238, 0.61);
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    overflow-wrap: break-word;

}

@media(max-width:800px) {
    .id {
        width: 100%;
    }

    .text1 {
        font-size: 20px;
    }
}

h6 {
    margin-bottom: 0px !important;
}

.idsize {
    font-size: 20px;


}

.view-btn {
    line-height: 42px;
    padding-left: 40px;
    padding-right: 40px;
    /* border-radius: 25px; */
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    display: inline-block;
    font-weight: 500;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    outline: none !important;
    margin: 0 auto;
    display: flex;
}

@media only screen and (max-width:600px) {
    .view-btn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.view-btn:hover {
    background-color: #144E82;
    color: white;
}

.view-btn:active {
    box-shadow: 0 0 20px #144E82;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.break-word {
    word-wrap: break-word;
}

.margin-top {
    margin-top: 50px;
}

.normal {
    font-weight: normal;
}

.cus-name {
    font-size: 20px;
    margin-top: 8px;
}

@media(max-width:600px) {
    .cus-name {
        font-size: 10px;
    }
}

.grid-header-container {
    display: grid;
    grid-template-columns: 8fr auto auto;
    grid-gap: 10px;
}

/* .padding{
    padding: 20px 10%;
  }
  .bgcolor{
      background-color: #144E82;
  } */
.logowidth {
    width: 200px;
}

@media(max-width:600px) {
    .logowidth {
        width: 100px;
    }

    .grid-header-container {

        grid-template-columns: auto auto;

    }

    .grid-header-item {
        grid-column: 1/3;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.margintopdrop {
    margin-top: 5px;
}

.box {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.box select {


    margin: 5px 0px;
    padding: 10px 10px;
    /* width: 270px; */
    width: 100%;
    /* height: 34px; */
    color: #144E82;
    border: 1px solid #144E82;
    background: #eee;
    outline: none;
    border-radius: 5px;
}

.box:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
    padding: 30px;
}

.flexcontainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: 50%;
    -webkit-transform: translate(0px, -50%);
            transform: translate(0px, -50%);

}

.dropdownbox {
    position: fixed;
    background: white;
    width: 40%;
    height: 70%;
    top: 50%;
    left: 150%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10;
    border-top-left-radius: 200px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    padding: 40px;
}

.generatebtn {
    border: 1px solid #144E82;
    background: transparent;
    border-radius: 5px;
    font-size: 15px;
    color: #144E82;
    padding: 5px 10px;
    width: 100px;
    font-weight: bold;
    outline: none !important;
    float: right;

}

.generatebtn:hover {
    background-color: #144E82;
    color: white;
    font-weight: bold;
}

.MuiDialog-paper {
    margin: 0px !important;
}

.widthinputbox {
    width: 100%;
}

.padding {
    padding: 10px;
    border-radius: 5px;
}

.sizetitle {
    font-size: 30px;
}

.paddingbottomdomain {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.loaderloginbtn {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid rgba(0, 0, 0, 0.15);
    width: 16px;
    height: 16px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-left: 45px;

}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.margintop {
    margin-top: 20px !important;
}

.posotionmelzologo {
    position: fixed;
    top: 20px;
    left: 20px;
}

.widthmelzologo {
    width: 30px;
}

.MuiDialog-paperWidthSm {
    width: 600px;
}

@media(max-width:600px) {

    .MuiDialog-paperWidthSm {
        width: 400px;
    }
}

.scrollbar-style::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
}

.scrollbar-style::-webkit-scrollbar {
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
}

.scrollbar-style::-webkit-scrollbar-thumb {
    background-color: rgba(240, 240, 240, 0.3);
}

.vert-scrollbar-style::-webkit-scrollbar-thumb {
    background-color: #546e7a;
    /*rgba(240, 240, 240, 0.3);*/
}

.vert-scrollbar-style::-webkit-scrollbar-track {
    background-color: #949494;
    /*rgba(0, 0, 0, 0);*/
}

.vert-scrollbar-style::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
}

#pie {
    height: 440px;
}



.dashboardbtn {
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
    font-weight: bold;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    outline: none !important;
    box-shadow: 0 0 10px #144E82;
    background-color: #144E82;
    color: white;
    border: 0;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}


@media only screen and (max-width:600px) {
    .dashboardbtn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.dashboardbtn:hover {
    background: transparent;
    border: 1px solid #144E82;
    color: #144E82;
}

.downloadbtn {

    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 47px;
    background-color: #144E82;
    border: 1.5px solid #144E82;
    height: 50px;
    width: 250px;
    text-transform: uppercase;
    background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(50%, #fff), color-stop(50%, #144E82));
    background-image: linear-gradient(to right, #fff, #fff 50%, #144E82 50%);
    background-size: 210% 100%;
    background-position: 99%;
    outline: none !important;
}

/* .downloadbtn:after {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 5px;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    content: "";
} */

.downloadbtn:hover {
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
    background-position: 0%;
    color: #144E82;}

/* .downloadbtn:hover:after {
    width: 100%;
    background: rgba(0, 0, 0, 0.02);
} */








.marginchart {
    margin-top: 10px;
    margin-bottom: 40px;
}

@media(max-width:600px) {
    .marginchart {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .marginchartcol {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.fontweight {
    font-weight: bold;
    text-decoration: underline;
}

.fontfamily {
    font-family: 'bold', sans-serif;

}

body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: #144E82;
    border-radius: 20px;
    border: 3px solid transparent;
}

#chart {
    height: 440px;
}

.schedule-tab {
    /* height: 140px; */
    display: flex;
  }
  
  .schedule-tab__item {
    width: 9.09%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    border-left: unset;
    position: relative;
    padding: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .schedule-tab__item:first-child {
    border-left: 1px solid #e6e6e6;
  }
  
  .schedule-tab__item h2 {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 10px;
    margin-top: 2px;
  }
  
  .schedule-tab__item p {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 14px;
    color: #144E82;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 0;
  }
  
  @media(max-width:990px) {
    .schedule-tab__item p {
      font-size: 7px;
    }
    .schedule-tab__item h2 {
      font-size: 8px;
    }
  }
  
  .schedule-tab__item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #144E82;
    z-index: -1;
    /* IE 9 */
    /* Safari 3-8 */
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
  }
  
  .schedule-tab__item:hover::before {
    /* IE 9 */
    /* Safari 3-8 */
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
  
  .schedule-tab__item:hover h2, .schedule-tab__item:hover p {
    color: #ffffff;
  }
  .schedule-tab__item:hover{
    background-color: #144E82;

  }
  .schedule-tab .tab-active {
    background-color: #144E82;
  }
  
  .schedule-tab .tab-active h2, .schedule-tab .tab-active p {
    color: #ffffff;
  }
  @media (max-width: 600px) {
    .schedule-table {
      padding-top: 40px;
    }
    /* .schedule-tab {
      height: 100px;
    } */
    .schedule-tab h2 {
      font-size: 25px;
    }
    .schedule-tab p {
      font-size: 14px;
    }
  }
  @media (max-width: 576px) {
    .schedule-table .row:first-child .schedule-table__time {
      padding-top: 15px;
    }
    .schedule-table__time {
      border-right: unset;
      padding: 30px 0 15px 0;
    }
    .schedule-table__event {
      width: 100%;
      padding: 0 0 15px 0;
      border: unset;
      margin: 0;
    }
    .schedule-table__speaker {
      padding: 0;
    }
    .break-time {
      height: 170px;
    }
    .break-time__coffee {
      padding: 0 0 0 15px;
      text-align: center;
    }
    .break-time__coffee h3 {
      margin: 0;
      line-height: 20px;
    }
    .schedule-tab {
      border-top: 1px solid #e6e6e6;
      flex-wrap: wrap;
      height: auto;
    }
    .schedule-tab .schedule-tab__item {
      width: 100%;
      height: 80px;
      border-left: 1px solid #e6e6e6;
      border-top: unset;
    }
  }
.content {
    padding: 50px;
    background-color: #F4F8FB;
    padding-top: 100px;
    height: 100%;
    overflow-y: auto;
}

@media only screen and (max-width: 992px) {
    .content {
        padding-top: 170px;
    }
}

.parent-card-list {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-bottom: 30px;
}

.card-item {
    width: 300px;
    height: 132px;
    background-color: #fff;
    margin: 10px;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05)
}

.inner-card-item {
    margin: 40px;
}

.inner-card-item h3 {
    display: inline-flex;
    /* position: absolute; */
    font-weight: 400;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.inner-card-item p {
    text-align: center;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color: #495057;
    font-weight: 400;
    margin-top: -10px;
}

@media only screen and (max-width: 804px) {
    .parent-card-list {
        justify-content: center;
    }
    .inner-card-item svg {
        margin-right: 40px;
    }
}

.daily-feed-card {
    background-color: #fff;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.daily-feed-header-props {
    padding: 20px;
    margin-bottom: 20px;
}

.daily-feed-header-title {
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color: #495057;
    font-weight: 400;
    size: 11px;
}

.daily-feed-brand {
    width: 50px;
    height: 15px;
    background-color: #5b7dff;
    position: absolute;
    right: 30px;
    top: 25px;
    border-radius: 5px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.daily-feed-parent {}

.daily-feed-inner {
    display: flex;
}

.daily-feed-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.daily-feed-text {
    color: #495057;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin: 10px;
}

.daily-feed-time {
    color: #495057;
    margin-left: 10px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
}

.daily-feed-daytime {
    color: #495057;
    margin-left: 10px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.daily-feed-button {
    background-color: #47BAC1;
    border-color: #47BAC1;
    color: #fff;
    height: 30px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.footer-props {
    display: flex;
    background: #fff;
    border: 1px solid #dee2e6;
    padding: 1rem 0.75rem;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.mobile-footer-item {
    display: none;
}

.footer-item-list {
    display: flex;
    padding-left: 20px;
    margin-bottom: 0px;
}

.footer-item-list li {
    list-style: none;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color: #495057;
    font-weight: 400;
    margin-right: 10px;
}

@media only screen and (max-width: 436px) {
    .footer-item-list {
        display: none;
    }
    .mobile-footer-item {
        display: flex;
        list-style: none;
        padding: 0px;
    }
    .footer-props {
        justify-content: center;
        right: 0px;
        /* padding: 10px 0px 0px; */
    }
}

.reset-button-props {
    background-color: orange;
    border-radius: 30px;
    width: 100px;
    color: #fff;
    margin-left: 20px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.datepicker-props {
    background-color: #fff;
    margin-bottom: 20px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.datePicker-header-title {
    padding: 10px 0px 0px 10px;
    font-style: italic
}

.datePicker-text-props {
    margin-right: 20px;
    text-align: center;
    font-style: italic;
    padding: 10px 0px 0px 10px;
}

.piechart-props {
    background-color: #fff;
    padding: 0px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    margin-bottom: 20px;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.piechart-header-props {
    padding: 10px 0px 0px 10px;
    font-style: italic;
}

.lineChart-props {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.lineChart-header-props {
    font-style: italic;
}

.calendar-props {
    display: block !important;
}

.appointment-props {
    background-color: #fff;
    padding: 0px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    margin-bottom: 20px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.05);
}

.appointment-header-props {
    padding: 10px 0px 0px 10px;
    font-style: italic;
}

.daily-appointment-props {
    background-color: #F4F8FB;
    height: 100px auto;
}

.daily-appointment-text {
    color: #495057;
    padding: 20px 0px 0px 20px;
    margin-bottom: 0px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 25px;
}

.daily-appointment-text-inner {
    color: #495057;
    padding: 10px 10px;
    margin-left: 10px;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.appointment-activity-props {
    padding: 20px;
}

.activity-logo {
    border: 4px solid #47BAC1;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.activity-line {
    border-right: 2px solid #47BAC1;
    width: 10.5px;
    height: 100px;
    border-color: #47BAC1;
    display: inline-block;
}

.activity-text-header {
    position: fixed;
    margin: -20px 0px 0px 20px;
}

.activity-text {
    margin: 5px 20px 0px 20px;
    position: fixed;
    display: inline-block;
}

.activity-logo-inner {
    margin-top: -50px;
}
@font-face {
  font-family: 'black';
  src: url(/static/media/proximanova-black-webfont.ea4034de.eot);
  src: url(/static/media/proximanova-black-webfont.ea4034de.eot?#iefix) format('embedded-opentype'),
    url(/static/media/proximanova-black-webfont.c0fad74e.woff2) format('woff2'),
    url(/static/media/proximanova-black-webfont.b7e2c353.woff) format('woff'),
    url(/static/media/proximanova-black-webfont.573ff7d5.ttf) format('truetype'),
    url(/static/media/proximanova-black-webfont.2a5fe8c0.svg#proxima_novablack) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bold';
  src: url(/static/media/proximanova-extrabold-webfont.2979e024.eot);
  src: url(/static/media/proximanova-extrabold-webfont.2979e024.eot?#iefix) format('embedded-opentype'),
    url(/static/media/proximanova-extrabold-webfont.35617150.woff2) format('woff2'),
    url(/static/media/proximanova-extrabold-webfont.f56366ec.woff) format('woff'),
    url(/static/media/proximanova-extrabold-webfont.5bed238d.ttf) format('truetype'),
    url(/static/media/proximanova-extrabold-webfont.d19b3934.svg#proxima_novaextrabold) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'regular';
  src: url(/static/media/proximanova-regular-webfont.11b59b70.eot);
  src: url(/static/media/proximanova-regular-webfont.11b59b70.eot?#iefix) format('embedded-opentype'),
    url(/static/media/proximanova-regular-webfont.100a0a13.woff2) format('woff2'),
    url(/static/media/proximanova-regular-webfont.5baab837.woff) format('woff'),
    url(/static/media/proximanova-regular-webfont.ea71bb45.ttf) format('truetype'),
    url(/static/media/proximanova-regular-webfont.5010fa7c.svg#proxima_nova_rgregular) format('svg');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}




* {
  box-sizing: border-box;
}

/* body {
  background: #222D32;
  font-family: 'Roboto', sans-serif;
} */
.bg {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 0px;
  background-size: cover;
  position: fixed;
  background-attachment: scroll;
  background-image: url(/static/media/backimg.1432da67.png);

}

.bg img {
  position: absolute;
}

.bg video {
  position: absolute;
}

.bg-span {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
}

.wrapper {
  /* background: #969393; */

  /* background-image: url("../src/assets/images/banner.jpg"); */
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 0px;
  background-size: cover;
  position: fixed;
  background-attachment: scroll;
  /* background-image: linear-gradient(72deg, #ee5979, #144E82); */

}

.login-box {
  /* height: auto;
  background: white;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding-bottom: 20px; */

  /* margin-top: 125px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  /* background-image: url("../src/assets/images/002.png"); */
  border-radius: 30px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  position: fixed;
  width: 300px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
}

/* @media(max-width:600px) {
  .login-box {
    width: 300px;
  }
} */

.login-key {
  height: 50px;
  font-size: 80px;
  line-height: 100px;
  background: -webkit-linear-gradient(#27EF9F, #0DB8DE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media(max-width:600px) {
  .login-key {
    height: 5px;
  }
}

.login-title {
  margin-top: 20px;
  text-align: center;
  font-size: 25px;
  letter-spacing: 2px;
  /* margin-top: 15px; */
  font-weight: bold;
  color: #144E82;
  margin-bottom: 15px;
  font-family: 'black', sans-serif;
}

.login-form {
  margin-top: 25px;
  text-align: left;
}

.inputloginpage {
  background: #f8f8f8;
  border: 0px;
  height: 43px;
  line-height: 43px;
  padding: 0px 15px;
  color: #777777;
  font-size: 14px;
  box-sizing: border-box;
  width: 45%;
  margin: 20px 2%;
  border: 1px transparent solid;
  width: 100%;
  font-family: 'regular', sans-serif !important;
}



.form-group {
  margin-bottom: 40px;
  outline: 0px;
}

@media(max-width:600px) {
  .form-group {
    margin-bottom: 0px;
  }
}

.form-control:focus {
  border-color: inherit;
  box-shadow: none;
  border-bottom: 2px solid #144E82;
  outline: 0;
  background-color: white;
  color: #144E82;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0;
}

label {
  margin-bottom: 0px;
}

.form-control-label {
  font-size: 10px;
  color: #6C6C6C;
  font-weight: bold;
  letter-spacing: 1px;
}

.submitbtn {
  line-height: 32px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  outline: none !important;
  box-shadow: 0 0 10px #144E82;
  background-color: #144E82;
  color: white;
  border: 0;
  border-radius: 5px;
  width: 120px;
  height: 40px;
  float: right;
}

.submitbtn:hover {
  background: transparent;
  border: 1px solid #144E82;
  color: #144E82;
}

.submitbtn:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/*buttons*/
.commonbutton {
  position: relative;
  font-size: 14px;
  font-family: 'bold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  background: #144E82;
  padding: 15px 25px;
  display: inline-block;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  /* line-height: 28px; */
  height: 50px;
  width: 120px;
  border: 0;
  outline: none !important;
}

.commonbutton.size-2 {
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.commonbutton.size-3 {
  line-height: 20px;
  padding: 10px 12px;
  font-size: 10px;
}

.commonbutton.type-1 {
  background: #fff;
  color: #144E82;
  text-shadow: none;
  box-shadow: none;
}

.commonbutton:after {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 5px;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  content: "";
}

.commonbutton.type-1:after {
  border: 2px #144E82 solid;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
}

.commonbutton span {
  position: relative;
  float: left;
  z-index: 1;
}

.commonbutton:hover {
  color: #fff;
}

.commonbutton.type-1:hover {
  background: #144E82;
}

.commonbutton:hover:after {
  width: 100%;
  background: rgba(0, 0, 0, 0.02);
}

.commonbutton.black {
  background: #222222 !important;
  color: #144E82;
}

.commonbutton.black:hover {
  color: #fff;
}

.commonbutton.black:after {
  background: #000;
}

.registerbtn {
  line-height: 32px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  outline: none !important;
  box-shadow: 0 0 10px #144E82;
  background-color: #144E82;
  color: white;
  border: 0;
  border-radius: 5px;
  width: 130px;
  height: 40px;




}

.registerbtn:hover {
  background: transparent;
  border: 1px solid #144E82;
  color: #144E82;
}

.registerbtn:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.marginrightbtn {
  margin-right: 10px;
}

.login-btm {
  float: left;
}

.login-button {
  padding-right: 0px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 20px;
}

.login-text {
  text-align: left;
  padding-left: 0px;
  color: #A2A4A4;
}

.loginbttm {
  padding: 0px;
}

.container2 {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container2 {
    max-width: 700px;
  }
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: auto;
  z-index: 100;
  margin-bottom: 10px;
}

.footer-text {
  font-size: 1.2vw;
  text-align: center;
  color: white;
  margin-bottom: 0px;
  text-decoration: none;

}


@media(max-width:600px) {
  .footer-text {
    text-align: center;
    color: white;
    font-size: 10px;
    margin-bottom: 0px;
  }


}

.melzotext {
  color: white !important;
  margin-left: 5px;
  text-decoration: underline !important;

}

.melzotext:hover {
  color: white !important;
  margin-left: 5px;
  text-decoration: underline !important;
}

.logoposition {
  position: fixed;
  top: 20px;
  left: 20px;



}



.flexlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.widthsalemaxlogo {
  width: 130px;
}

@media(max-width:600px) {

  .widthsalemaxlogo {
    width: 70px;
  }
}

.widthangleslogo {
  width: 300px;
}



.underline {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.MuiTypography-h6 {
  color: #144E82;
  font-weight: bold !important;
  font-size: 30px !important;
}

.MuiDialog-paperWidthSm {
  width: 300px;
}

.logopositionleft {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

.widthangleslogo {
  width: 140px;
}

@media(max-width:600px) {
  .widthangleslogo {
    width: 100px;
  }
}

.logopositionright {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.widthboihringerlogo {
  width: 140px;
}

@media(max-width:600px) {
  .widthboihringerlogo {
    width: 100px;
  }
}
.registerbutton {
  color: #fff;
  font-family: "JosefinSans-600", sans-serif;
  display: inline-block;
  text-align: center;
  line-height: 47px;
  background-color: #144E82;
  border: 1.5px solid #144E82;
  height: 45px;
  width: 130px;
  text-transform: uppercase;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(50%, #fff), color-stop(50%, #144E82));
  background-image: linear-gradient(to right, #fff, #fff 50%, #144E82 50%);
  background-size: 210% 100%;
  background-position: 99%;
  cursor: pointer;
}

.registerbutton:hover {
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
  transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
  color: #144E82;
}
html,
body {
    height: 100%;
    font-family: 'regular', sans-serif !important;
}

.sidebar-props {
    background-color: #BBBDBD;
    box-sizing: border-box;
    /* padding-top: 15px; */
    /* padding-left: 30px; */
    /* width: 100%; */
    height: 100%;
    /* padding: 10px; */
}

@media only screen and (max-width: 992px) {
    .sidebar-props {
        height: auto;
        /* padding: 10px; */
    }
}

.compdisplay {
    display: block;
}

@media(max-width:1090px) {
    .compdisplay {
        display: none;
    }
    .mobdisplay {
        display: block;
    }
}

.mobdisplay {
    display: none;
}

.dashboard-props {
    padding: 0;
}

.main {
    position: absolute;
    height: 100%;
    width: 100%;
}

.heightrow {
    height: 100vh;
}

.watchVideo {
    background: #274c7f !important;
    color: #fff !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    display: block !important;
    margin: auto 0 !important;
}

.chips {
    margin: 2px;
    text-transform: capitalize;
    background: #274c7f !important;
    color: #fff !important;
}
