@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

:root {
    --parent-color: rgba(0, 0, 0, 0.1);
}

.sidebar-header {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* position: fixed; */
}

@media only screen and (max-width: 992px) {
    .sidebar-header {
        flex-direction: row;
    }
}

.header-text {
    font-size: 20px;
    margin: 10px;
    color: #fff;
    /* margin-top: 0px; */
}

.sidebar-menu-title {
    color: #ACB5BD;
    font-size: 14px;
    padding-left: 10px;
}

@media only screen and (min-width: 992px) {

    .sidebar-menu {
        /* position: fixed; */
        top: 15%;
        width: 100%;
    }

    /* 
    .sidebar-header {
        position: fixed;
    } */
}

@media only screen and (max-width: 992px) {
    .sidebar-menu {
        display: none;
    }
}

.parent-menu {
    display: flex;
    width: 100% !important;
    text-align: left !important;
}

.parent-menu:hover {
    background-color: var(--parent-color);
}

.parent-menu-text {
    color: #ffffff;
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 21px;
}

.parent-menu:hover {
    text-decoration: none !important;
}

.child-menu {
    background-color: #310d2b;
}

.child-menu-item-props {
    background-color: transparent !important;
    border-color: transparent !important;
    width: stretch;
    text-align: left !important;
    /* padding: 10px 0px 10px 60px !important; */
    color: #4D4D4D !important;
    font-size: 14px !important;
    margin: 0px;
}

.child-menu-item-props:focus {
    box-shadow: none !important;
}

.child-menu-item-props:hover {
    background-color: var(--parent-color) !important;
}

.icon-title-props {
    color: #C1C7CE;
}

.selected-sidebar-item {
    background-color: var(--parent-color) !important;
    color: #fff !important;
}

.widthlogo {
    width: 100%;
    /* height: 40px; */
}

@media(max-width:600px) {
    .widthlogo {
        width: 150px;
    }
}

.widthmenu {
    width: 30px;
    /* height: 40px; */
}

.paddingright {
    padding-right: 0px;
}

.hrline {
    border-top: 1px solid white;
}

@media(max-width:601px) {
    .compdisplay {
        display: none;
    }
}

.positionmenu {
    display: none;
}

@media only screen and (max-width: 992px) {
    .sidebar-menu {
        display: none;
    }

    .positionmenu {
        display: block;
        position: fixed;
        top: 20px;
        left: 10px;
    }
}

.overlaymenu {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.menuposition {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.flexmenu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    font-weight: bold;
    top: 16px;
    /* right: 10px; */
    font-family: "Nunito Sans", -apple-system, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    list-style-type: none;
    padding: 20px;
    width: 100%;
}

.menubg {
    width: 80%;
    height: 100%;
    /* right: -20%; */
    position: fixed;
    background: #BBBDBD;
    z-index: 3;
    display: none;
}

.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.flexmenulist {
    margin-top: 20px;
    color: #36cca5;

}

.openmenuanimation {
    animation: lefthtoleftmenu 0.5s forwards;

}

@keyframes lefthtoleftmenu {
    0% {
        left: -150%;
    }

    100% {
        left: 0%;
    }
}

.closmenuanimation {
    animation: lefttorightmenu 1s forwards;
}

@keyframes lefttorightmenu {
    0% {
        left: 0%;
    }

    100% {
        left: -150%;
    }
}

.sidebar-props {
    height: auto;
    width: 16%;
    position: fixed;
    top: 0px;
    z-index: 1000;
    /* padding: 10px; */
}

@media only screen and (max-width: 992px) {
    .sidebar-props {
        height: auto;
        position: fixed;
        top: 0px;
        z-index: 1000;
        width: 100%;
        /* padding: 10px; */
    }
}

.widthlisticon {
    width: 18px;
    height: 16px;
}

.positionlogo {
    /* position: fixed; */
    /* top: 20px;
    left: 20px; */
    background-color: white;
    width: 60px;
    height: 60px;
    z-index: 100;
    padding: 2px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;

}



.flexlogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-link:hover {
    color: transparent !important;
}

.btn-link {
    color: transparent !important;
}

.btn.focus,
.btn:focus {
    box-shadow: 0 0 0 0 !important;
}